import { VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';

let Wrapper = styled('div')`
  height: min(90vh, 600px);
  width: 500px;
  max-width: 100vw;
  border: var(--vapor-border);
  margin: 0px auto;
  border-radius: 15px;

  @media screen and (max-width: 600px) {
    border: none;
  }
`;

export let Box = ({ children }: { children: React.ReactNode }) => {
  return (
    <VerticalCenter>
      <div>
        <Wrapper>{children}</Wrapper>
      </div>
    </VerticalCenter>
  );
};

export let BoxInner = styled(VerticalCenter)`
  padding: 60px;

  @media screen and (max-width: 600px) {
    padding: 10px 15px;
  }
`;
