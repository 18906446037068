import { styled } from 'buttered';

export let Center = styled('div')`
  width: fit-content;
  margin: 0px auto;
  text-align: center;
`;

export let CenterContent = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
